/**
 * DashboardLayout Component
 *
 * Purpose: Contains the navigation menu and the route
 *
 * @author Marisol Torres <marisol@inclancommunications.com>
 * @date 18/11/2021 12:51:25 PM
 */

import React, { useState, useEffect, useRef, useContext } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import * as moment from "moment";
import {
  Container,
  Row,
  Col,
  Nav,
  OverlayTrigger,
  Overlay,
  Popover,
  Tooltip,
  ListGroup,
  Figure,
  Spinner,
} from "react-bootstrap";
import { Switch, Route, useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import firebase from "../firebase/firebase";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/messaging";

/** Components */
import OrganizationSearch from "../components/menu/OrganizationSearch";
import NotificationsPopover from "../components/menu/NotificationsPopover";
import ServiceIcon from "../components/menu/ServiceIcon";
import OverlayBlock from "../components/menu/OverlayBlock";
import ModalServicePremium from "../components/menu/ModalServicePremium";
import ModalNotificationSend from "../components/menu/ModalNotificationSend";
import {
  goTo,
  goToHref,
  imgOrganization,
  domainCookie,
  dateCookie,
  GetNotificationToken,
  setUserRestriction,
  userInfo,
  validateUserRoleIds,
} from "../components/global/GlobalTools";
import MobileMenuView from "../pages/MobileMenuView";
import PushNotifications from "./PushNotifications.js"; // Get notifications manager
export const NavTabsContext = React.createContext();
import ModalSuccess from "./global/modals/ModalSuccess";
import ModalAddPhoneNumber from "./PhoneNumber/ModalAddPhoneNumber";

/** Services */
import { organizationGet, serviceVote } from "../api/Organizations";
import { notificationsGet } from "../api/Notifications";
import { userGet } from "../api/Users";
import {
  GetPermissions,
  Logout,
  TokenDestroy,
  deleteDeviceToken,
} from "../api/Session";
import { MainContext } from "../App";
import { ValidateSection } from "../components/global/ValidateSections";
import { GetSchoolCycle } from "../api/Cycles";
import { GetUserRelativeStudent } from "../api/DashboardRelativeStudent";

export default function DashboardLayout({ routes }) {
  const [t, i18n] = useTranslation(["menu", "global", "reports"]); //In the array that is passed as an argument in useTranslation, the translations are loaded per module
  const history = useHistory();
  const [disabledScreen, setDisabledScreen] = useState();
  const [organization, setOrganization] = useState({});
  const [organizationSelected, setOrganizationSelected] = useState(null);
  const [subscriptions, setSubscriptions] = useState({});
  const [servicesPremium, setServicesPremium] = useState({});
  const [googleForEducation, setGoogleForEducation] = useState(0);
  const [serviceChat, setServiceChat] = useState(0);
  const [group, setGroup] = useState("");
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState({});
  const [optionsOrganizations, setOptionsOrganizations] = useState([]); //List of organizations
  const [chatContact, setChatContact] = useState();
  const [show, setShow] = useState(false);
  const [showAngleDown, setShowAngleDown] = useState(true);
  const [showAngleUp, setShowAngleUp] = useState(false);
  const [isOpenSearch, setOpenSearch] = useState(false);
  const [modalPremium, setModalPremium] = useState(false);
  const [typeOfService, setypeOfService] = useState("");
  const [showNotificationSend, setShowNotificationSend] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [heightPopover, setHeightPopover] = useState(76); //Accumulate the height of the content of popovers
  const [heightIconos, setHeightIconos] = useState(76); //Accumulate the height of the content of the services
  const [positionSearch, setPositionSearch] = useState(0); //
  const target = useRef(null);
  const prefix = process.env.REACT_APP_PREFIX;
  const platform = process.env.REACT_APP_PLATFORM;
  const token = Cookies.get(`cmToken${prefix}`); //Obtain cmToken's cookie from the domain .campusmovil.com
  const [organizationLogo, setOrganizationLogo] = React.useState("");
  const [pathName, setPathName] = useState(history.location.pathname);
  const { permissions, updatePermissions } = useContext(MainContext);
  const [selectedTab, setSelectedTab] = useState(null);
  const [widthSize] = useState(window.innerWidth);
  const [mobileSize] = useState(810);
  const [hideCards, setHideCards] = useState(false);
  const [servicesObj, setServicesObj] = useState(false);
  const hideMenu = Cookies.get(`cmHideMenus${prefix}`);
  const [hasDebtRestriction, setHasDebtRestriction] = useState(false);
  const [openModalSuccess, setOpenModalSucces] = useState(false);
  const [modalSuccessTtile, setModalSuccessTitle] = useState("");
  const [modalSuccessMessage, setModalSuccessMessage] = useState("");
  const [txtBtnAccept, setTxtBtnAccept] = useState("");
  const [txtBtnCancel, setTxtBtnCancel] = useState("");
  const [showModalAddPhone, setShowModalAddPhone] = useState(false);
  const [unreadNotificationIds, setUnreadNotificationIds] = useState([]);

  /**
   * Execute this on every render of this component
   */
  useEffect(() => {
    //Check if you are logged in
    if (!token) {
      //clean localStorage
      TokenDestroy();
      history.push(`/`);
    }
    // Get group
    if (validateUserRoleIds([6, 8])) {
      getUserRelativeStudent();
    }
    if (hideMenu == 1) {
      goToHref("/payments");
    }
  }, []);

  //Effect that listens for the change of the path to store it in a variable
  useEffect(() => {
    setPathName(history.location.pathname);
  }, [history.location.pathname]);

  const popoverUserStyle = {
    firstItem: {
      borderTop: 0,
      borderLeft: 0,
      borderRight: 0,
      marginLeft: "-15px",
      marginRight: "-15px",
      marginTop: "-15px",
      cursor: "pointer",
    },
    secondItem: {
      borderLeft: 0,
      borderRight: 0,
      marginLeft: "-15px",
      marginRight: "-15px",
      cursor: "pointer",
    },
    thirdItem: {
      borderLeft: 0,
      borderRight: 0,
      borderBottom: 0,
      marginLeft: "-15px",
      marginRight: "-15px",
      marginBottom: "-15px",
      cursor: "pointer",
    },
  };

  const angle = {
    height: "0.5vh",
    paddingTop: "0.02vh",
    width: "inherit",
    textAlign: "center",
    margin: "auto",
  };

  /**
   * Purpose: Sign out
   */
  const logout = () => {
    deleteDeviceToken(GetNotificationToken());
    Logout().then(() => {
      firebase
        .auth()
        .signOut()
        .then(function () {
          // Sign-out successful.
        })
        .catch(function () {
          // An error happened.
        });
      TokenDestroy();
      goToHref("/login");
    });
  };

  /**
   * Purpose: Calculate the "height" property for the menu sections
   */
  const getHeightContent = () => {
    let imgOrganization = document.getElementById("img-organization");
    if (imgOrganization) {
      let coordinates = imgOrganization.getBoundingClientRect();
      setPositionSearch(coordinates.left);
    }
    let elementHeight = 90;
    if (googleForEducation) {
      elementHeight += 41;
    }
    if (serviceChat) {
      elementHeight += 41;
    }
    let totalHeight = elementHeight * 0.11494252873563218;
    setHeightPopover(`${totalHeight}vh`);

    /** Assign the height of Content icons (Services) */
    let heightAngle = 1;
    let heightSeparator = 0.7 * 2;
    let heightOrgization = 9.494736842105263;
    let heightElements =
      100 - (heightOrgization + heightAngle + heightSeparator + totalHeight);
    setHeightIconos(`${heightElements}vh`);
    hasScroll();
  };

  /**
   * Purpose: Defines if it has active scroll
   */
  const hasScroll = () => {
    //Define if it has scrollm
    let contentIcons = document.getElementById("content-icons");
    if (contentIcons !== null) {
      let navIcons = document.getElementById("nav-icons");
      let elementNavIcons = navIcons.getBoundingClientRect();
      let elementContentIcons = contentIcons.getBoundingClientRect();

      if (elementNavIcons.height > elementContentIcons.height) {
        setShowAngleDown(true);
      } else {
        setShowAngleUp(false);
        setShowAngleDown(false);
      }
    }
  };

  //Read cookies and store them in localStorage
  let getSiteCookies = Cookies.get(); //Obtains cookies from the domain .campusmovil.com
  let keysCookies = Object.keys(getSiteCookies);
  let valuesCookies = Object.values(getSiteCookies);
  keysCookies.map((key, index) => {
    //Save cookies in localStorage
    localStorage.setItem(key, valuesCookies[index]);
  });

  /**
   * Purpose: Gets the active subscriptions from the organization
   * @param {array} arraySubscriptions
   */
  const activeSubscriptions = (arraySubscriptions) => {
    let subscription = {};
    for (let pos in arraySubscriptions) {
      switch (arraySubscriptions[pos].id) {
        case "1": {
          subscription.class = "active";
          break;
        }
        case "2": {
          subscription.events = "active";
          break;
        }
        case "3": {
          subscription.students = "active";
          break;
        }
        case "4": {
          subscription.staff = "active";
          break;
        }
        case "5": {
          subscription.web = "active";
          break;
        }
        case "6": {
          subscription.reviews = "active";
          break;
        }
        case "7": {
          subscription.payments = "active";
          break;
        }
        case "8": {
          subscription.training = "active";
          break;
        }
        case "9": {
          subscription.crm = "active";
          break;
        }
        case "10": {
          subscription.chat = "active";
          setServiceChat(1);
          break;
        }
        case "11": {
          subscription.app = "active";
          break;
        }
        case "12": {
          subscription.parents = "active";
          break;
        }
      }
    }
    //Set the subscriptions in the localStorage
    localStorage.setItem(
      `cmSubscriptions${prefix}`,
      JSON.stringify(subscription)
    );
    setSubscriptions(subscription);
  };

  /**
   * Purpose: You get the premium services of the organization
   * @param {array} services
   */
  const services = (services) => {
    for (let pos in services) {
      switch (services[pos].service_id) {
        case "1": {
          //SIE
          servicesPremium.sie = {
            id: services[pos].service_id,
            organization_service_id: services[pos].organization_service_id,
            premium: services[pos].premium == 1,
          };
          break;
        }
        case "2": {
          //Website
          servicesPremium.website = {
            id: services[pos].service_id,
            organization_service_id: services[pos].organization_service_id,
            premium: services[pos].premium == 1,
          };
          break;
        }
        case "3": {
          //Payments
          servicesPremium.payments = {
            id: services[pos].service_id,
            organization_service_id: services[pos].organization_service_id,
            premium: services[pos].premium == 1,
          };
          break;
        }
        case "4": {
          //App
          servicesPremium.app = {
            id: services[pos].service_id,
            organization_service_id: services[pos].organization_service_id,
            premium: services[pos].premium == 1,
          };
          break;
        }
        case "5": {
          //Reviews
          servicesPremium.reviews = {
            id: services[pos].service_id,
            organization_service_id: services[pos].organization_service_id,
            premium: services[pos].premium == 1,
          };
          break;
        }
        case "6": {
          //Chat
          servicesPremium.chat = {
            id: services[pos].service_id,
            organization_service_id: services[pos].organization_service_id,
            premium: services[pos].premium == 1,
          };
          break;
        }
      }
      setServicesPremium(servicesPremium);
    }
    setLoading(false);
  };

  /**
   * Structure the services obtained to refresh the cookies
   * @param {*} data
   */
  const upateLocalServices = (data) => {
    let services = {};
    for (let service of data) {
      switch (service.service_id) {
        case "1": {
          //SIE
          services.SIE = {
            id: service.service_id,
            active: service.premium == 0 ? false : true,
            organizationServiceId: service.organization_service_id,
          };
          break;
        }
        case "2": {
          //Website
          services.Website = {
            id: service.service_id,
            active: service.premium == 0 ? false : true,
            organizationServiceId: service.organization_service_id,
          };
          break;
        }
        case "3": {
          //Payments
          services.Payments = {
            id: service.service_id,
            active: service.premium == 0 ? false : true,
            organizationServiceId: service.organization_service_id,
          };
          break;
        }
        case "4": {
          //App
          services.App = {
            id: service.service_id,
            active: service.premium == 0 ? false : true,
            organizationServiceId: service.organization_service_id,
          };
          break;
        }
        case "5": {
          //Reviews
          services.Reviews = {
            id: service.service_id,
            active: service.premium == 0 ? false : true,
            organizationServiceId: service.organization_service_id,
          };
          break;
        }
        case "6": {
          //Chat
          services.Chat = {
            id: service.service_id,
            active: service.premium == 0 ? false : true,
            organizationServiceId: service.organization_service_id,
          };
          break;
        }
        case "7": {
          //DocuSing
          services.DocuSing = {
            id: service.service_id,
            active: service.premium == 0 ? false : true,
            organizationServiceId: service.organization_service_id,
          };
          break;
        }
        case "8": {
          //SCE
          services.SCE = {
            id: service.service_id,
            active: service.premium == 0 ? false : true,
            organizationServiceId: service.organization_service_id,
          };
          break;
        }
        case "9": {
          //CRM
          services.CRM = {
            id: service.service_id,
            active: service.premium == 0 ? false : true,
            organizationServiceId: service.organization_service_id,
          };
          break;
        }
        case "10": {
          //DigitalContent
          services.DigitalContent = {
            id: service.service_id,
            active: service.premium == 0 ? false : true,
            organizationServiceId: service.organization_service_id,
          };
          break;
        }
        case "11": {
          //GoogleForEducations
          services.GoogleForEducations = {
            id: service.service_id,
            active: service.premium == 0 ? false : true,
            organizationServiceId: service.organization_service_id,
          };
          break;
        }
        case "12": {
          //SAP
          services.SAP = {
            id: service.service_id,
            active: service.premium == 0 ? false : true,
            organizationServiceId: service.organization_service_id,
          };
          break;
        }
      }
    }
    //Set services in state
    setServicesObj(services);
    //Update the cookie organizationID (current site / previous site)
    Cookies.set(`${prefix}Services`, JSON.stringify(services), {
      expires: dateCookie(),
      domain: domainCookie(), //Domain to Beta and Live, for local change this for localhost
    });
    //Set the subscriptions in the localStorage
    localStorage.setItem(`${prefix}Services`, JSON.stringify(services));
  };

  /** Get the organization information */
  const getOrganization = () => {
    organizationGet().then((result) => {
      setOrganization(result.data.data[0]);
      services(result.data.data[0].services);
      upateLocalServices(result.data.data[0].services);
      //Save organization image url in the context
      imgOrganization(result.data.data[0].logo_image_url, true);
      setOrganizationLogo(result.data.data[0].logo_image_url);
      setGoogleForEducation(
        result.data.data[0].organization_info.google_for_education
      ); //Show google icon or not
      activeSubscriptions(result.data.data[0].subscriptions);
      getNotifications();

      //Check if the openpay payment processor has the card payment method
      let organizationInfo = result.data.data[0].organization_info;
      let openpayCard = false;
      let evoCard = false;

      if (organizationInfo.openpay_payment_types) {
        openpayCard =
          organizationInfo.openpay_payment_types.find(
            (element) => element == "card"
          ) == "card";
      }
      //Find evo card payment type
      if (organizationInfo.evo_payment_types) {
        evoCard =
          organizationInfo.evo_payment_types.find(
            (element) => element == "card"
          ) == "card";
      }
      setHideCards(evoCard ? true : !openpayCard);

      let orgInfoName = `cmOrganizationInfo${prefix}`;
      localStorage.setItem(orgInfoName, JSON.stringify(result.data.data[0]));
    });
  };

  /** Get updated permissions */
  const getPermissions = () => {
    GetPermissions(Cookies.get(`cmToken${prefix}`)).then((result) => {
      updatePermissions(result.data);
    });
  };

  // Obtain the school cycles and generate the cycle cookie
  const getSchoolCycles = () => {
    GetSchoolCycle().then((result) => {
      let data = result.data;
      for (let index = 0; index < data.length; index++) {
        data[index].label = data[index].school_cycle;
        data[index].value = data[index].id;
      }
      localStorage.setItem(`cycles${prefix}`, JSON.stringify(data));
    });
  };

  /** Get the list of organizations */
  const getUser = () => {
    userGet(localStorage.getItem(`cmUserID${prefix}`)).then((result) => {
      let organizationSelected = localStorage.getItem(
        `cmOrganizationID${prefix}`
      );
      let data = result.data.data;
      let organizations = [];
      let userOrgs = data.organizations;
      for (let i = 0; i < userOrgs.length; i++) {
        if (userOrgs[i].platform === platform) {
          organizations.push({
            label: userOrgs[i].name,
            value: userOrgs[i].id,
            plan_type: userOrgs[i].plan_type_id,
          });
        }
      }
      if (data.groups[0].id == 6 && (!data.phone || data.phone == "")) {
        toogleShowModalAddPhone(true);
      }
      let organization = organizations.find(
        (organization) => organization.value === organizationSelected
      );
      setOrganizationSelected(organization);
      setOptionsOrganizations(organizations);
      setChatContact(data.chat_contact); //It is used for validations in the menu
      let userInfoName = `cmUserProfile${prefix}`;
      localStorage.setItem(userInfoName, JSON.stringify(data));
    });
  };

  /**
   * Purpose: Get the notifications
   */
  const getNotifications = () => {
    const request = {
      limit: 5,
      offset: 0,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    notificationsGet(request).then((result) => {
      const data = result.data?.data;
      //scheduled notifications
      for (const scheduled of data.scheduled) {
        const sendDate = moment(scheduled.send_date);
        const utcDate = moment.parseZone(sendDate).utc().local();
        scheduled.send_date = utcDate;
      }
      //notifications sent
      for (const sent of data.sent) {
        const sendDate = moment(sent.send_date);
        const utcDate = moment.parseZone(sendDate).utc().local();
        sent.send_date = utcDate;
      }
      setNotifications(data);
      setUnreadNotificationIds(data.unread_ids);
      getUser();
    });
  };

  /**
   * Purpose: Modal for Premium services
   * @param {*} status
   * @param {*} typeOfService
   */
  const ModalPremium = (status, typeOfService) => {
    setModalPremium(status);
    setypeOfService(typeOfService);
  };

  /**
   * Purpose: Notification sending modality
   * @param {*} status
   */
  const modalNotificationSend = (status) => {
    setIsSaving(true);
    if (status) {
      // Based on which icon the request comes from, it is the id of the service that will be sent.
      // A switch was created to be able to expand if necessary
      let service_id = false;
      switch (typeOfService) {
        case "7": {
          //Payments
          service_id = servicesPremium.payments.organization_service_id;
          break;
        }
        case "9": {
          //Website
          service_id = servicesPremium.sie.organization_service_id;
          break;
        }
      }
      serviceVote(service_id).then(() => {
        setShowNotificationSend(status);
        setModalPremium(false);
        setIsSaving(false);
      });
    }
  };

  /**
   * Purpose: Displays the arrows up and down
   * @param {Boolean} event
   */
  const searchOrganizationToggle = (event) => {
    setOpenSearch(event);
  };

  const contentPopoverUser = (
    <ListGroup>
      {permissions.user_profile.access && (
        <ListGroup.Item
          className="over-list"
          onClick={() => goToHref(`/user`)}
          style={popoverUserStyle.firstItem}
        >
          {t("popoverUser.myProfile")}
        </ListGroup.Item>
      )}

      {validateUserRoleIds([1]) && ValidateSection(permissions, "settingsCM") ? (
        <ListGroup.Item
          className="over-list"
          onClick={() => history.push("/admin")}
          style={popoverUserStyle.secondItem}
        >
          {t("popoverUser.manage")}
        </ListGroup.Item>
      ) : (
        ""
      )}
      <ListGroup.Item
        className="over-list"
        onClick={() => logout()}
        style={popoverUserStyle.thirdItem}
      >
        {t("popoverUser.logout")}
      </ListGroup.Item>
    </ListGroup>
  );

  const contentPopoverGoogle = (
    <Row>
      <Col xs={4} sm={3}>
        <Figure>
          <Figure.Image
            width={78}
            height={78}
            className="icon-google"
            src="/img/google_icons/class.png"
            onClick={() => goTo("https://classroom.google.com")}
          />
          <Figure.Caption style={{ textAlign: "center" }}>
            Classroom
          </Figure.Caption>
        </Figure>
      </Col>

      <Col xs={4} sm={3}>
        <Figure>
          <Figure.Image
            width={78}
            height={78}
            className="icon-google"
            src="/img/google_icons/gmail.png"
            onClick={() => goTo("https://www.google.com/gmail")}
          />
          <Figure.Caption style={{ textAlign: "center" }}>Gmail</Figure.Caption>
        </Figure>
      </Col>

      <Col xs={4} sm={3}>
        <Figure>
          <Figure.Image
            width={78}
            height={78}
            className="icon-google"
            src="/img/google_icons/calendar.png"
            onClick={() => goTo("https://www.google.com/calendar")}
          />
          <Figure.Caption style={{ textAlign: "center" }}>
            Calendar
          </Figure.Caption>
        </Figure>
      </Col>

      <Col xs={4} sm={3}>
        <Figure>
          <Figure.Image
            width={78}
            height={78}
            className="icon-google"
            src="/img/google_icons/keep.png"
            onClick={() => goTo("https://keep.google.com")}
          />
          <Figure.Caption style={{ textAlign: "center" }}>
            Google keep
          </Figure.Caption>
        </Figure>
      </Col>

      <Col xs={4} sm={3}>
        <Figure>
          <Figure.Image
            width={78}
            height={78}
            className="icon-google"
            src="/img/google_icons/drive.png"
            onClick={() => goTo("https://www.google.com/drive")}
          />
          <Figure.Caption style={{ textAlign: "center" }}>Drive</Figure.Caption>
        </Figure>
      </Col>

      <Col xs={4} sm={3}>
        <Figure>
          <Figure.Image
            width={78}
            height={78}
            className="icon-google"
            src="/img/google_icons/docs.png"
            onClick={() => goTo("https://www.google.com/docs")}
          />
          <Figure.Caption style={{ textAlign: "center" }}>Docs</Figure.Caption>
        </Figure>
      </Col>

      <Col xs={4} sm={3}>
        <Figure>
          <Figure.Image
            width={78}
            height={78}
            className="icon-google"
            src="/img/google_icons/sheets.png"
            onClick={() => goTo("https://www.google.com/sheets")}
          />
          <Figure.Caption style={{ textAlign: "center" }}>
            Sheets
          </Figure.Caption>
        </Figure>
      </Col>

      <Col xs={4} sm={3}>
        <Figure>
          <Figure.Image
            width={78}
            height={78}
            className="icon-google"
            src="/img/google_icons/slides.png"
            onClick={() => goTo("https://www.google.com/slides")}
          />
          <Figure.Caption style={{ textAlign: "center" }}>
            Presentation
          </Figure.Caption>
        </Figure>
      </Col>
    </Row>
  );

  /**
   * Popover to show the organization search engine
   */
  const popoverSearhOrganizations = (
    <Popover
      id={`popover-search`}
      style={{ maxWidth: "390px" }}
      bsPrefix="menu-search"
    >
      <Popover.Body style={{ padding: "0 6px" }}>
        <OrganizationSearch
          prefix={prefix}
          organization={organization}
          setOrganization={setOrganization}
          organizationSelected={organizationSelected}
          setOrganizationSelected={setOrganizationSelected}
          chatContact={chatContact}
          setChatContact={setChatContact}
          //isLocked={isLocked}
          //setLocked={setLocked}
          isOpenSearch={isOpenSearch}
          options={optionsOrganizations}
          setDisabledScreen={setDisabledScreen}
          setGoogleForEducation={setGoogleForEducation}
          activeSubscriptions={activeSubscriptions}
          services={services}
          positionSearch={positionSearch}
          t={t}
          searchOrganizationToggle={searchOrganizationToggle}
          setOrganizationLogo={setOrganizationLogo}
          getUser={getUser}
        />
      </Popover.Body>
    </Popover>
  );

  /**
   * Popover that shows the list of notifications
   */
  const popoverNotifications = (
    <Popover
      id={`popover-positioned-right`}
      style={{ maxWidth: "456px", width: "340px" }}
    >
      <Popover.Body>
        <NotificationsPopover
          prefix={prefix}
          notifications={notifications}
          goToHref={goToHref}
          t={t}
          unreadNotificationIds={unreadNotificationIds}
          setUnreadNotificationIds={setUnreadNotificationIds}
        />
      </Popover.Body>
    </Popover>
  );

  /**
   * Popover showing User icon options
   */
  const popoverUser = (
    <Popover id={`popover-positioned-right`}>
      <Popover.Body>{contentPopoverUser}</Popover.Body>
    </Popover>
  );

  /**
   * Popover showing Google icon options
   */
  const popoverGoogle = (
    <Popover
      id={`popover-positioned-right`}
      style={
        widthSize <= mobileSize ? { maxWidth: "320px" } : { maxWidth: "370px" }
      }
    >
      <Popover.Body style={{}}>{contentPopoverGoogle}</Popover.Body>
    </Popover>
  );

  /**
   * Purpose: Redirect to the corresponding urls
   * @param {*} eventKey
   */
  const moduleSelect = (eventKey) => {
    let url = process.env.REACT_APP_V1;
    switch (eventKey) {
      case "/dashboard": {
        history.push("/dashboard");
        break;
      }
      case "/app": {
        history.push("/app");
        break;
      }
      case "/crm/leads": {
        if (servicesPremium.sie && !servicesPremium.sie.premium) {
          ModalPremium(true, "9");
        } else {
          history.push("/crm/leads");
        }
        break;
      }
      case "/payments": {
        if (validateUserRoleIds([16])) {
          history.push("/payments/scholarships");
        } else if (validateUserRoleIds([1, 2])) {
          history.push("/payments");
        } else {
          window.location.href = `${url}/reports`;
        }
        break;
      }
      case "/estudiantes": {
        history.push("/students");
        break;
      }
      case "/eventos": {
        window.location.href = `${url}/eventos`;
        break;
      }
      case "/news": {
        history.push("/news");
        break;
      }
      case "/class": {
        history.push("/class");
        break;
      }
      case "/relatives": {
        history.push("/relatives");
        break;
      }
      case "/reviews/analytics": {
        if (servicesPremium.reviews && servicesPremium.reviews.premium) {
          window.location.href = `${url}/reviews/analytics`;
        } else {
          ModalPremium(true, "6");
        }
        break;
      }
      case "/admin/users": {
        window.location.href = `${url}/admin/users`;
        break;
      }
      case "/staff": {
        history.push("/staff");
        break;
      }
      case "/activity_reports": {
        window.location.href = `${url}/activity_reports`;
        break;
      }
      case "/website": {
        window.location.href = `${url}/website`;
        break;
      }
      case "/configuraciones/ciclo": {
        window.location.href = `${url}/configuraciones/ciclo`;
        break;
      }
      case "/training": {
        window.location.href = `${url}/training`;
        break;
      }
      case "/admin/permissions": {
        prefix == "Beta"
          ? history.push("/admin/permissions")
          : (window.location.href = `${url}/admin/permissions`);
        break;
      }
      case "/chat": {
        if (servicesPremium.chat && servicesPremium.chat.premium) {
          window.location.href = `${url}/chat`;
        } else {
          ModalPremium(true, "10");
        }
        break;
      }
    }
  };

  /**
   * Purpose: Identifies if the scroll reaches the end to show the down arrow icon
   */
  const endScroll = () => {
    let element = document.getElementById("content-icons");
    if (element !== null) {
      if (element.scrollTop + element.offsetHeight == element.offsetHeight) {
        setShowAngleUp(false);
      } else if (
        element.offsetHeight + element.scrollTop <=
        element.scrollHeight
      ) {
        setShowAngleUp(true);
      }

      if (element.offsetHeight + element.scrollTop >= element.scrollHeight) {
        setShowAngleDown(false);
      } else {
        setShowAngleDown(true);
      }
    }
  };

  // Method to update complementary data to change organization
  const UpdateOrgnizationssupplements = () => {
    getSchoolCycles();
    // Remove student cycle filter
    localStorage.removeItem("filterCycleStudents");
    // Clear CRM cycle filter
    localStorage.removeItem(`cycleCrmStorage${prefix}`);
    localStorage.removeItem("filterCycleProspect");
    // Keep deleting cookie at the end
    let domain = window.location.href.includes(".campusmovil.com")
      ? ".campusmovil.com"
      : window.location.hostname;
    Cookies.remove(`cmChangeOrganization${prefix}`, {
      domain: domain,
      path: "/",
    });
  };

  /**Initial loading */
  useEffect(() => {
    setDisabledScreen(false);
    if (!token) {
      goToHref(`/login`);
    } else {
      setGroup(localStorage.getItem(`cmGroup${prefix}`));
      //Change the language
      i18n.changeLanguage(localStorage.getItem(`cmLanguage${prefix}`));
      //Get organization data
      getOrganization();
      // Get permissions
      getPermissions();
      // Detect organization change
      const isChangeOrganization = Cookies.get(`cmChangeOrganization${prefix}`);
      // Apply organization change actions
      if (isChangeOrganization) {
        UpdateOrgnizationssupplements();
      }
    }
    // the service worker in service will be removed to avoid conflicts such as: the same token for different users
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
          registration.unregister();
        }
      });
    }
  }, []);

  /**Update the height property of the menu*/
  useEffect(() => {
    getHeightContent();
  }, [googleForEducation, serviceChat]);

  /**Check if the scroll is active*/
  useEffect(() => {
    hasScroll();
  }, [heightIconos, organization]);

  /**
   * Puporse: Get User Relative and Student info
   */
  const getUserRelativeStudent = () => {
    const user = userInfo();
    const request = { user_id: user.id };
    GetUserRelativeStudent(request).then((result) => {
      if (result.status) {
        const userInfo = result.data;
        //Storage of votes in localStorage
        localStorage.setItem(
          `cmUserVotes${prefix}`,
          JSON.stringify(userInfo.votes)
        );
        // Get Restriction
        let hasDebtRestriction = false;
        const students = userInfo.students || [];
        if (students && Array.isArray(students) && students.length !== 0) {
          for (let item of students) {
            hasDebtRestriction =
              item &&
                item.has_debt_restriction &&
                item.has_debt_restriction == 1
                ? true
                : false;
            if (hasDebtRestriction) {
              break;
            }
          }
        }
        // Set restriction
        setHasDebtRestriction(hasDebtRestriction);
        // Set Cookie
        setUserRestriction(hasDebtRestriction);
      }
    });
  };

  /**
   * Puporse: Displays the restriction modal
   */
  const showRestrictions = () => {
    setHasDebtRestriction(true);
    setModalSuccessMessage(t("global:modalAlertClassesRestrictions.message"));
    setModalSuccessTitle(t("global:modalAlertClassesRestrictions.title"));
    setTxtBtnAccept(t("reports:paymentsTabs.statementAccount"));
    setTxtBtnCancel(t("global:buttons.close"));
    setOpenModalSucces(true);
  };

  /**
   * Open or close manners
   * @param {Boolean} value
   */
  const toogleShowModalAddPhone = (value) => {
    setShowModalAddPhone(value);
  };

  return (
    <Container
      fluid
      style={{ paddingLeft: 0, paddingRight: 0 }}
      className="menu"
    >
      {/**Organization Desk's Notifications */}
      {organization && organization.api_key && (
        <PushNotifications
          apiKey={organization.api_key}
          getNotifications={getNotifications}
          organizationId={organization.id}
        />
      )}
      <OverlayBlock
        disabledScreen={disabledScreen}
        campusmovil={true}
        message={t("changingOrganization")}
      />
      <Row style={{ marginRight: 0, marginLeft: 0 }}>
        {loading ? (
          ""
        ) : (
          <Col
            md={1}
            xs={1}
            style={{ padding: 0, background: "#ffffff" }}
            className="layout-menu d-none d-lg-block d-xl-block d-xxl-block"
          >
            <div
              style={{
                position: "fixed",
                width: "inherit",
                height: "inherit",
                background: "#ffffff",
              }}
              id="content-menu"
            >
              <div
                id="content-organizations"
                style={{ height: "9.494736842105263vh" }}
              >
                <Nav
                  className="flex-column"
                  variant="campus"
                  style={{ background: "transparent" }}
                >
                  <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    overlay={popoverSearhOrganizations}
                    bsPrefix="menu-search"
                    rootClose
                    onToggle={(e) => {
                      searchOrganizationToggle(e);
                    }}
                    show={isOpenSearch}
                  >
                    <Nav.Link disabled={optionsOrganizations.length == 0}>
                      <div
                        style={{ width: "fit-content", margin: "auto" }}
                        id="img-organization"
                        className={
                          optionsOrganizations.length == 0
                            ? "disbled-element"
                            : ""
                        }
                      >
                        <img
                          className="thumbnail-image"
                          src={organizationLogo}
                          alt="Selected Organization"
                          style={{
                            height: "6vh",
                            borderRadius: "4px",
                            verticaAlign: "top",
                          }}
                          ref={target}
                          onMouseOver={() => setShow(!show)}
                          onMouseOut={() => setShow(!show)}
                        />
                        <img
                          src={
                            isOpenSearch
                              ? "/img/angle_up.svg"
                              : "/img/angle_down.svg"
                          }
                          style={{
                            height: "0.5vh",
                            margin: "auto",
                            marginTop: "5px",
                            display: "block",
                          }}
                        />
                        <Overlay
                          target={target.current}
                          show={show}
                          placement="right"
                        >
                          {(props) => (
                            <Tooltip id="overlay-name-organization" {...props}>
                              {organization.name}
                            </Tooltip>
                          )}
                        </Overlay>
                      </div>
                      {optionsOrganizations.length === 0 ||
                        organizationLogo === "" ? (
                        <Spinner
                          as="span"
                          animation="border"
                          role="status"
                          aria-hidden="true"
                          variant="secondary"
                          style={{
                            position: "absolute",
                            top: "25px",
                            left: "40%",
                            width: "1.4rem",
                            height: "1.4rem",
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </Nav.Link>
                  </OverlayTrigger>
                </Nav>
              </div>

              <div
                className="separator"
                id="separator"
                style={{
                  height: "0.7vh",
                  paddingTop: 0,
                  paddingBottom: "0.45vh",
                }}
              >
                <hr
                  style={{ color: "#777779", margin: "0 auto" }}
                  width="80%"
                />
              </div>

              <div className="angle-up" id="angle-up" style={angle}>
                {showAngleUp ? (
                  <img
                    src="/img/angle_up.svg"
                    style={{ height: "0.5vh", verticalAlign: "top" }}
                  />
                ) : (
                  <img
                    src="/img/angle_up_white.svg"
                    style={{ height: "0.5vh", verticalAlign: "top" }}
                  />
                )}
              </div>

              {/** sections Modules */}
              <div
                onScroll={() => endScroll()}
                id="content-icons"
                style={{ height: heightIconos }}
              >
                <Nav
                  activeKey={history.location.pathname}
                  className="flex-column"
                  variant="campus"
                  onSelect={(eventKey) => {
                    if (
                      hasDebtRestriction &&
                      (validateUserRoleIds([6, 8])) &&
                      eventKey == "/class"
                    ) {
                      showRestrictions();
                    } else {
                      moduleSelect(eventKey);
                    }
                  }}
                  id="nav-icons"
                >
                  {/** Dashboard */}
                  <a
                    href={
                      servicesObj &&
                        (servicesObj.Payments.active ||
                          servicesObj.App.active ||
                          servicesObj.SCE.active) &&
                        permissions.admin_dashboard?.access
                        ? "/dashboard"
                        : `${process.env.REACT_APP_V1}/landing_page`
                    }
                    style={{ textDecoration: "none" }}
                  >
                    <Nav.Link
                      onClick={() => {
                        (servicesObj.Payments.active ||
                          servicesObj.App.active ||
                          servicesObj.SCE.active) &&
                          permissions.admin_dashboard?.access
                          ? history.push("/dashboard")
                          : goToHref("/landing_page");
                      }}
                    >
                      <ServiceIcon
                        image="/img/dashboard-icons/default/dashboard.svg"
                        id="item-dashboard"
                        title="Dashboard"
                        style={{ height: "5.412054120541206vh" }}
                      />
                    </Nav.Link>
                  </a>

                  {/** APP */}
                  {subscriptions.app && ValidateSection(permissions, "app") ? (
                    <a href="/app" style={{ textDecoration: "none" }}>
                      <Nav.Link eventKey="/app">
                        <ServiceIcon
                          image="/img/dashboard-icons/default/app.svg"
                          id="item-app"
                          title="APP"
                          style={{ height: "5.412054120541206vh" }}
                        />
                      </Nav.Link>
                    </a>
                  ) : (
                    ""
                  )}

                  {/** CRM */}
                  {subscriptions.crm && ValidateSection(permissions, "crm") ? (
                    <a href="/crm/leads" style={{ textDecoration: "none" }}>
                      <Nav.Link eventKey="/crm/leads">
                        <ServiceIcon
                          image="/img/dashboard-icons/default/crm.svg"
                          id="item-crm"
                          title="CRM"
                          validation={
                            servicesPremium.sie && !servicesPremium.sie.premium
                              ? "premium-service"
                              : ""
                          }
                          style={{ height: "5.412054120541206vh" }}
                        />
                      </Nav.Link>
                    </a>
                  ) : (
                    ""
                  )}

                  {/** Payments */}
                  {subscriptions.payments &&
                    ValidateSection(permissions, "payments") && servicesPremium.payments.premium ? (
                    <a href="/payments" style={{ textDecoration: "none" }}>
                      <Nav.Link
                        eventKey="/payments"
                        active={
                          pathName === "/payments/transactions" ||
                          pathName === "/payments" ||
                          pathName === "/payments/income" ||
                          pathName === "/payments/scholarships" ||
                          pathName === "/payments/typeScholarships"
                        }
                      >
                        <ServiceIcon
                          image="/img/dashboard-icons/default/payments.svg"
                          id="item-payments"
                          title={t("payments")}
                          style={{ height: "5.412054120541206vh" }}
                        />
                      </Nav.Link>
                    </a>
                  ) : (
                    ""
                  )}

                  {/** Students */}
                  {subscriptions.students &&
                    ValidateSection(permissions, "students") ? (
                    <a href="/students" style={{ textDecoration: "none" }}>
                      <Nav.Link
                        eventKey="/estudiantes"
                        active={pathName === "/students"}
                      >
                        <ServiceIcon
                          image="/img/dashboard-icons/default/students.svg"
                          id="item-students"
                          title={t("students")}
                          style={{ height: "5.412054120541206vh" }}
                        />
                      </Nav.Link>
                    </a>
                  ) : (
                    ""
                  )}

                  {/** Parents */}
                  {subscriptions.parents &&
                    ValidateSection(permissions, "family") ? (
                    <a href="/relatives" style={{ textDecoration: "none" }}>
                      <Nav.Link
                        eventKey="/relatives"
                        active={pathName === "/relatives"}
                      >
                        <ServiceIcon
                          image="/img/dashboard-icons/default/relatives.svg"
                          id="item-relatives"
                          title={t("relatives")}
                          style={{ height: "5.412054120541206vh" }}
                        />
                      </Nav.Link>
                    </a>
                  ) : (
                    ""
                  )}

                  {/** Events */}
                  {subscriptions.events &&
                    ValidateSection(permissions, "events") ? (
                    <a
                      href={`${process.env.REACT_APP_V1}/eventos`}
                      style={{ textDecoration: "none" }}
                    >
                      <Nav.Link eventKey="/eventos">
                        <ServiceIcon
                          image="/img/dashboard-icons/default/events.svg"
                          id="item-events"
                          title={t("events")}
                          style={{ height: "5.412054120541206vh" }}
                        />
                      </Nav.Link>
                    </a>
                  ) : (
                    ""
                  )}

                  {/** News */}
                  {subscriptions.web && ValidateSection(permissions, "news") ? (
                    <a
                      href="/news" style={{ textDecoration: "none" }}>
                      <Nav.Link
                        eventKey="/news"
                        active={
                          pathName === "/news" ||
                          pathName === "/news/categories" ||
                          pathName === "/news/tags" ||
                          pathName === "/news/tickers"
                        }>
                        <ServiceIcon
                          image="/img/dashboard-icons/default/news.svg"
                          id="item-news"
                          title={t("news")}
                          style={{ height: "5.412054120541206vh" }}
                        />
                      </Nav.Link>
                    </a>
                  ) : (
                    ""
                  )}

                  {/** Class */}
                  {subscriptions.class &&
                    ValidateSection(permissions, "classes") ? (
                    <a href="/class" style={{ textDecoration: "none" }}>
                      <Nav.Link
                        eventKey="/class"
                        active={
                          pathName === "/class" ||
                          pathName === "/scores" ||
                          pathName === "/subjects"
                        }
                      >
                        <ServiceIcon
                          image="/img/dashboard-icons/default/class.svg"
                          id="item-class"
                          title={t("classes")}
                          style={{ height: "5.412054120541206vh" }}
                        />
                      </Nav.Link>
                    </a>
                  ) : (
                    ""
                  )}

                  {/** Reviews  */}
                  {subscriptions.reviews &&
                    ValidateSection(permissions, "reviews") ? (
                    <a
                      href={`${process.env.REACT_APP_V1}/reviews/analytics`}
                      style={{ textDecoration: "none" }}
                    >
                      <Nav.Link eventKey="/reviews/analytics">
                        <ServiceIcon
                          image="/img/dashboard-icons/default/reviews.svg"
                          id="item-reviews"
                          title={t("reviews")}
                          validation={
                            servicesPremium.reviews &&
                              !servicesPremium.reviews.premium
                              ? "premium-service"
                              : ""
                          }
                          style={{ height: "5.412054120541206vh" }}
                        />
                      </Nav.Link>
                    </a>
                  ) : (
                    ""
                  )}

                  {/** Teachers/Staff */}
                  {subscriptions.staff &&
                    ValidateSection(permissions, "personal") ? (
                    <a href="/staff" style={{ textDecoration: "none" }}>
                      <Nav.Link eventKey="/staff">
                        <ServiceIcon
                          image="/img/dashboard-icons/default/staff.svg"
                          id="item-teachers"
                          title={t("staff")}
                          style={{ height: "5.412054120541206vh" }}
                        />
                      </Nav.Link>
                    </a>
                  ) : (
                    ""
                  )}

                  {/** Reports */}
                  {ValidateSection(permissions, "reports") ? (
                    <a
                      href={`${process.env.REACT_APP_V1}/activity_reports`}
                      style={{ textDecoration: "none" }}
                    >
                      <Nav.Link eventKey="/activity_reports">
                        <ServiceIcon
                          image="/img/dashboard-icons/default/reports.svg"
                          id="item-reports"
                          title={t("reports")}
                          style={{ height: "5.412054120541206vh" }}
                        />
                      </Nav.Link>
                    </a>
                  ) : (
                    ""
                  )}

                  {/** Website */}
                  {subscriptions.web &&
                    ValidateSection(permissions, "website") ? (
                    <a
                      href={`${process.env.REACT_APP_V1}/website`}
                      style={{ textDecoration: "none" }}
                    >
                      <Nav.Link eventKey="/website">
                        <ServiceIcon
                          image="/img/dashboard-icons/default/website.svg"
                          id="item-website"
                          title={t("website")}
                          style={{ height: "5.412054120541206vh" }}
                        />
                      </Nav.Link>
                    </a>
                  ) : (
                    ""
                  )}

                  {/** Configuration */}
                  {ValidateSection(permissions, "configurations") ? (
                    <a
                      href={`${process.env.REACT_APP_V1}/configuraciones/ciclo`}
                      style={{ textDecoration: "none" }}
                    >
                      <Nav.Link eventKey="/configuraciones/ciclo">
                        <ServiceIcon
                          image="/img/dashboard-icons/default/configuration.svg"
                          id="item-configuration"
                          title={t("configuration")}
                          style={{ height: "5.412054120541206vh" }}
                        />
                      </Nav.Link>
                    </a>
                  ) : (
                    ""
                  )}

                  {/** Training */}
                  {subscriptions.training &&
                    ValidateSection(permissions, "trainings") &&
                    platform == "Campus Movil" &&
                    platform != "Uplift" ? (
                    <a
                      href={`${process.env.REACT_APP_V1}/training`}
                      style={{ textDecoration: "none" }}
                    >
                      <Nav.Link eventKey="/training">
                        <ServiceIcon
                          image="/img/dashboard-icons/default/training.svg"
                          id="item-training"
                          title={t("training")}
                          style={{ height: "5.412054120541206vh" }}
                        />
                      </Nav.Link>
                    </a>
                  ) : (
                    ""
                  )}
                </Nav>
              </div>

              <div className="angle-down" id="angle-down" style={angle}>
                {showAngleDown ? (
                  <img
                    src="/img/angle_down.svg"
                    style={{ height: "0.5vh", verticalAlign: "top" }}
                  />
                ) : (
                  <img
                    src="/img/angle_down_white.svg"
                    style={{ height: "0.5vh", verticalAlign: "top" }}
                  />
                )}
              </div>

              <div
                className="separator"
                id="separator"
                style={{
                  height: "0.7vh",
                  paddingTop: "0.45vh",
                  paddingBottom: 0,
                }}
              >
                <hr
                  style={{ color: "#777779", margin: "0 auto" }}
                  width="80%"
                />
              </div>

              {/** sections Iconos */}
              <div
                className="popovers-content"
                id="popovers-content"
                style={{ height: heightPopover }}
              >
                <Nav
                  activeKey={history.location.pathname}
                  className="flex-column"
                  variant="campus"
                  id="nav-icons"
                  onSelect={moduleSelect}
                >
                  {/** Chat */}
                  {(subscriptions.chat &&
                    ValidateSection(permissions, "chat")) ||
                    (ValidateSection(permissions, "chat") && chatContact) ? (
                    <a
                      href={`${process.env.REACT_APP_V1}/chat`}
                      style={{ textDecoration: "none" }}
                    >
                      <Nav.Link
                        eventKey="/chat"
                        style={{ height: "4.674046740467404vh" }}
                      >
                        <ServiceIcon
                          image="/img/dashboard-icons/default/chat.svg"
                          id="item-chat"
                          validation={
                            servicesPremium.chat &&
                              !servicesPremium.chat.premium
                              ? "premium-icon"
                              : ""
                          }
                          style={{ height: "2.459016393442623vh" }}
                        />
                      </Nav.Link>
                    </a>
                  ) : (
                    ""
                  )}

                  {/** Notification */}
                  {ValidateSection(permissions, "notifications") && (
                    <OverlayTrigger
                      trigger="click"
                      placement="right"
                      overlay={popoverNotifications}
                      rootClose
                    >
                      <Nav.Link
                        eventKey="notificatons"
                        style={{ height: "4.674046740467404vh" }}
                      >
                        <ServiceIcon
                          image={
                            unreadNotificationIds?.length
                              ? "/img/dashboard-icons/default/bell-notification.png"
                              : "/img/dashboard-icons/default/bell-regular.svg"
                          }
                          id="item-notifications"
                          style={{ height: "2.459016393442623vh" }}
                        />
                      </Nav.Link>
                    </OverlayTrigger>
                  )}
                  {/** Google */}
                  {googleForEducation &&
                    ValidateSection(permissions, "google") ? (
                    <OverlayTrigger
                      trigger="click"
                      placement="right"
                      overlay={popoverGoogle}
                      rootClose
                    >
                      <Nav.Link
                        eventKey="/google"
                        style={{ height: "4.674046740467404vh" }}
                      >
                        <ServiceIcon
                          image="/img/dashboard-icons/default/google-brands.svg"
                          id="item-google"
                          style={{ height: "2.459016393442623vh" }}
                        />
                      </Nav.Link>
                    </OverlayTrigger>
                  ) : (
                    ""
                  )}

                  {/** User */}
                  {ValidateSection(permissions, "perfil") && (
                    <OverlayTrigger
                      trigger="click"
                      placement="right"
                      overlay={popoverUser}
                      rootClose
                    >
                      <Nav.Link
                        eventKey="/user"
                        style={{ height: "4.674046740467404vh" }}
                      >
                        <ServiceIcon
                          image="/img/dashboard-icons/default/user-light.svg"
                          id="item-user"
                          style={{ height: "2.459016393442623vh" }}
                        />
                      </Nav.Link>
                    </OverlayTrigger>
                  )}
                </Nav>
              </div>
            </div>
            <ModalServicePremium
              modalPremium={modalPremium}
              group={group}
              typeOfService={typeOfService}
              setModalPremium={setModalPremium}
              modalNotificationSend={modalNotificationSend}
              isSaving={isSaving}
              t={t}
            />
            <ModalNotificationSend
              showNotificationSend={showNotificationSend}
              setShowNotificationSend={setShowNotificationSend}
              t={t}
            />
            {openModalSuccess && (
              <ModalSuccess
                showModalSuccess={openModalSuccess}
                size="md"
                title={modalSuccessTtile}
                message={modalSuccessMessage}
                txtBtnAccept={txtBtnAccept}
                fnAcceptButton={() => goToHref("/payments/accountStatus")}
                fnCancelButton={() => setOpenModalSucces(false)}
                txtBtnCancel={txtBtnCancel}
              />
            )}
          </Col>
        )}
        {/* component for responsive mobile and tablet view */}

        {(history.location.pathname === "/payments" ||
          history.location.pathname === "/payments/transactions" ||
          history.location.pathname === "/payments/debt" ||
          history.location.pathname === "/payments/income" ||
          history.location.pathname === "/payments/scholarships" ||
          history.location.pathname === "/payments/typeScholarships") &&
          hideMenu ? (
          ""
        ) : (
          <Col
            md={12}
            className="layout-menu d-block d-lg-none d-xl-none d-xxl-none"
          >
            <MobileMenuView
              setDisabledScreen={setDisabledScreen}
              setOrganizationLogo={setOrganizationLogo}
              setOrganization={setOrganization}
              options={optionsOrganizations}
              setOrganizationSelected={setOrganizationSelected}
              organizationSelected={organizationSelected}
              activeSubscriptions={activeSubscriptions}
              subscriptions={subscriptions}
              services={services}
              servicesPremium={servicesPremium}
              setGoogleForEducation={setGoogleForEducation}
              googleForEducation={googleForEducation}
              popoverUser={popoverUser}
              popoverGoogle={popoverGoogle}
              popoverNotifications={popoverNotifications}
              setSelectedTab={setSelectedTab}
              selectedTab={selectedTab}
              chatContact={chatContact}
              hideCards={hideCards}
              organization={organization}
              unreadNotificationIds={unreadNotificationIds}
            />
          </Col>
        )}
        <Col xs={12} md={12} lg={11} style={{}} className="layout-views">
          <NavTabsContext.Provider value={{ selectedTab }}>
            <Switch>
              {routes.map((route, i) => (
                <RouteWithSubRoutes key={i} {...route} />
              ))}
            </Switch>
          </NavTabsContext.Provider>
        </Col>
      </Row>
      {showModalAddPhone && (
        <ModalAddPhoneNumber
          showModalAddPhone={showModalAddPhone}
          toogleShowModalAddPhone={toogleShowModalAddPhone}
        />
      )}
    </Container>
  );
}

// A special wrapper for <Route> that knows how to
// handle "sub"-routes by passing them in a `routes`
// prop to the component it renders.
function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      render={(props) => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} routes={route.routes} api={route.api} />
      )}
    />
  );
}

DashboardLayout.propTypes = {
  routes: PropTypes.array,
};
