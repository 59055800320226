import React, { useEffect, useState } from "react";
import { Row, Col, Carousel } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { GetEvents } from "../../api/DashboardRelativeStudent";
import * as moment from "moment";
import PropTypes from "prop-types";
import SeeAllModule from "./admin/SeeAllModule";
import EventDetailsModal from "./EventDetailsModal";

const customStyles = {
  lazy: {
    height: "25px",
  },
  lazyImg: {
    height: "230px",
    width: "100%",
  },
  texUpper: {
    textTransform: "uppercase",
  },
};

const dummyEvent = [
  {
    name: "CAMPEONATO DE NATACION",
    img: "https://www.dir.cat/blog/wp-content/uploads/2017/02/natacion-mejor-deporte-iniciar-actividad-fisica-828x430.jpg",
    description: "SEPTIEMBRE 01,23",
  },
  {
    name: "CAMPEONATO DE MATEMATICAS",
    img: "https://www.educaciontrespuntocero.com/wp-content/uploads/2018/04/matematicas-978x652.jpg",
    description: "SEPTIEMBRE 04,23",
  },
];

const lazyLoadingEvents = () => {
  return (
    <div>
      <Row className="table-lazyLoading">
        <Col md={6} className="mt-3">
          <div style={customStyles.lazy}></div>
        </Col>
        <Col md={12} className="mt-2">
          <div style={customStyles.lazyImg}></div>
        </Col>
      </Row>
    </div>
  );
};

const EventsView = (props) => {
  const [t] = useTranslation(["dashboardStudent"]);
  const prefix = process.env.REACT_APP_PREFIX;
  const [events, setEvents] = useState([]);
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [showEventDetailsModal, setShowEventDetailsModal] = useState(false);
  const [eventSelected, setEventSelected] = useState({});

  /**
   * Gets the events of the app with its api Key
   */
  const getEvents = () => {
    setLoadingInfo(true);
    if (localStorage.getItem(`cmOrganizationInfo${prefix}`)) {
      const orgInfo = JSON.parse(
        localStorage.getItem(`cmOrganizationInfo${prefix}`)
      );
      const apiKey = orgInfo.api_key;
      let request = {
        api_key: apiKey,
        events_count: 5,
      };
      GetEvents(request)
        .then((result) => {
          if (result.status) {
            let events = [];
            let nextEvents = result.data.next_event || [];
            let otherEvents = result.data.other_events || [];

            if (Array.isArray(nextEvents)) {
              events = events.concat(nextEvents);
            } else {
              events.push(nextEvents);
            }

            if (Array.isArray(otherEvents)) {
              events = events.concat(otherEvents);
            } else {
              events.push(otherEvents);
            }

            let formatEvents = events.map((item) => {
              return {
                id: item.id,
                name: item.title,
                img: item.images[0].image_url,
                location: item.location,
                socialMedia: item.social_media || [],
                website: item.website_url,
                description: moment
                  .unix(item.start_time)
                  .local()
                  .format("MMMM DD,YYYY"),
                starTime: moment
                  .unix(item.start_time)
                  .local()
                  .format(
                    "DD [" + t("textModals.textDate") + "]  MMMM YYYY hh:mm a"
                  ),
                endTime: moment
                  .unix(item.end_time)
                  .local()
                  .format(
                    "DD [" + t("textModals.textDate") + "] MMMM YYYY hh:mm a"
                  ),
                info: item.description,
              };
            });
            setEvents(formatEvents);
          }
        })
        .catch()
        .finally(() => {
          setLoadingInfo(false);
        });
    } else {
      setTimeout(() => {
        getEvents();
      }, 1000);

    }
  };

  /**
   * initial effect of a single load
   */
  useEffect(() => {
    if (props.active == false) {
      setEvents(dummyEvent);
      setLoadingInfo(false);
    } else {
      getEvents();
    }
  }, [props.active]);

  return (
    <Row>
      <Col md={12}>
        {loadingInfo ? (
          lazyLoadingEvents()
        ) : (
          <>
            <Row>
              <Col xs={12} md={12} xl={12} className="mt-3">
                <i className="bi bi-calendar3 me-2 fs-6"></i>
                <b className="fs-6">{t("textEventsView.titleEvent")}</b>
              </Col>
            </Row>
            {events.length > 0 ? (
              <>
                <Row>
                  <Col md={12} className="mt-2">
                    <Carousel
                      id="events"
                      interval={showEventDetailsModal ? null : 2000}
                      style={{ position: "sticky" }}
                      fade
                    >
                      {events.map((item, index) => {
                        return (
                          <Carousel.Item
                            id={`carousel-item${index}`}
                            key={index}
                            role="button"
                            onClick={() => {
                              setEventSelected(item);
                              setShowEventDetailsModal(true);
                            }}
                          >
                            <div
                              style={{
                                height: "219px",
                                width: "100%",
                                borderRadius: "5px",
                                backgroundSize: "cover",
                                backgroundImage: `linear-gradient(rgba(0,0,0,0.1),rgba(0,0,0,0.9)),url(${item.img})`,
                              }}
                            />
                            <Carousel.Caption style={{ cursor: "pointer" }}>
                              <Row>
                                <Col className="float-start" md={12}>
                                  <h3 className="text-truncate ">
                                    {item.name}
                                  </h3>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={12}>
                                  <p
                                    className="float-start"
                                    style={customStyles.texUpper}
                                  >
                                    {item.description}
                                  </p>
                                </Col>
                              </Row>
                            </Carousel.Caption>
                          </Carousel.Item>
                        );
                      })}
                    </Carousel>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="mt-2">
                    <SeeAllModule isCMv1={true} path={"/eventos"} />
                  </Col>
                </Row>
              </>
            ) : (
              <Row>
                <Col md={12} className=" align-middle">
                  <p className="text-center">{t("dashboard:labels.noData")}</p>
                </Col>
              </Row>
            )}
            {showEventDetailsModal && (
              <EventDetailsModal
                showEventDetailsModal={showEventDetailsModal}
                size={"md"}
                eventDetail={eventSelected}
                fnCancelButton={() => setShowEventDetailsModal(false)}
                txtBtnCancel={t("global:buttons.close")}
              />
            )}
          </>
        )}
      </Col>
    </Row>
  );
};

export default EventsView;

EventsView.propTypes = {
  active: PropTypes.bool,
};
