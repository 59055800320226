import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Col,
  Modal,
  Row,
  Alert,
  OverlayTrigger,
  Tooltip,
  Form as FormB,
} from "react-bootstrap";
import { Form, Formik } from "formik";
import * as moment from "moment";
import * as Yup from "yup";
import * as Sentry from "@sentry/react";
import { createUsers } from "../../../api/Staff";

import FieldInput from "../../../components/global/form/FieldInput";
import FormSelect from "../../../components/global/form/FormSelect";
import FormCheck from "../../../components/global/form/FormCheck";
import PropTypes from "prop-types";
import ModalStaffCreated from "./ModalStaffCreated";
import { DatePicker } from "../../../components/global/form/DatePicker";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import TextLoading from "../../../components/global/TextLoading";
import { validateUserRoleIds } from "../../../components/global/GlobalTools";
const { userRoles } = require("../../../api/LocalCatalogues").LocalCatalogues();
export default function ModalCreateStaff(props) {
  const [t] = useTranslation([
    "staff",
    "crm",
    "global",
    "modalCreateRelative",
    "modalCreateStudent",
  ]);
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    permissions: "",
    email: "",
    password: "",
    test_account: false,
  });
  const [error, setError] = useState(false);
  const [showModalStaffConfirmations, setShowModalStaffConfirmations] =
    useState(false);
  const [newUserCreated, setNewUserCreated] = useState(null);
  const [typePassword, setTypePassword] = useState("password");
  const [disabledCreateUSer, setDisabledCreateUSer] = useState(false);
  const prefix = process.env.REACT_APP_PREFIX;
  const language = localStorage.getItem(`cmLanguage${prefix}`);
  const [optionRoles, setOptionRoles] = useState(userRoles);

  //Traslate calendar
  registerLocale("spanish", es);
  let Locallanguage = {};
  if (language === "spanish") {
    Locallanguage = { locale: "spanish" };
  }

  const tooltipPassword = (props) => {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {t("global:tooltipGlobal.textPassword")}
      </Tooltip>
    );
  };

  /**
   * Validate fields
   */
  const validateSchema = Yup.object().shape({
    firstName: Yup.string().required(
      t("crm:tableCrm.name") + " " + t("crm:isRequired")
    ),
    lastName: Yup.string().required(
      t("crm:requeridLastName") + " " + t("crm:isRequired")
    ),
    permissions: Yup.array()
      .min(1, t("modalConfirmationsStaff.textRol") + " " + t("crm:isRequired"))
      .required(t("modalConfirmationsStaff.textRol") + " " + t("crm:isRequired")),
    email: Yup.string().required(t("modalStaff.emailValid")),
    password: Yup.string()
      .required(t("modalStaff.thePassword") + " " + t("crm:isRequired"))
      .test(
        "formatPassword",
        t("global:tooltipGlobal.textPassword"),
        (password) => {
          if (password) {
            let passwordRegex = new RegExp(
              /(?=^(?:[^A-Z]*[A-Z]))(?=^(?:[^a-z]*[a-z]))(?=^(?:\D*\d))(?=^(?:\w*\W))^[A-Za-z\d\W]{8,}$/
            );
            return passwordRegex.test(password);
          }
        }
      ),
  });

  /**
   *Service to create the user
   * @param {obj} values
   */
  const CreateUsers = async (values) => {
    setDisabledCreateUSer(true);
    let data = {};
    data.first_name = values.firstName.toUpperCase();
    data.last_name = values.lastName.toUpperCase();
    data.email = values.email;
    data.password = values.password;
    data.groups = values.permissions;
    data.test_account = values.test_account;
    // valida si viene la fecha y darle formato
    if (values.birthDate) {
      data.birth_date = moment(values.birthDate).startOf("day").unix();
    }
    await createUsers(data)
      .then((result) => {
        if (result.error) {
          Sentry.captureException(Error(JSON.stringify(result.error)));
          setError(result.error.message);
        } else {
          closeModal();
          setShowModalStaffConfirmations(true);
          setNewUserCreated(result.data);
          props.listStaff();
        }
      })
      .finally(() => {
        setDisabledCreateUSer(false);
      });
  };

  /**
   * Function for clearing modal values and closing the modal
   */
  const closeModal = () => {
    setInitialValues({
      firstName: "",
      lastName: "",
      permissions: "",
      email: "",
      password: "",
    });
    props.setShowModalStaff(false);
    setError(false);
  };

  /**
   * Show/Hide password
   */
  const showHide = () => {
    let typeInput = typePassword === "password" ? "text" : "password";
    setTypePassword(typeInput);
  };

  useEffect(() => {
    Sentry.setTag("section", "Create Staff Modal");
    if (validateUserRoleIds(16) && !validateUserRoleIds([1,2])) {
      //only option of teacher available for school control
      setOptionRoles([userRoles.find((rol) => rol.id === 7)]);
    }
  }, []);

  return (
    <div>
      <Modal
        show={props.showModalStaff}
        bsSize="large"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title text-center">
            {t("staff.btnCreateStaff")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body mbottom10">
          <div>
            <Formik
              initialValues={initialValues}
              validationSchema={validateSchema}
              onSubmit={(values) => {
                CreateUsers(values);
              }}
            >
              {(formik) => (
                <Form>
                  {/* Name */}
                  <Row>
                    <Col md={12} lg={12}>
                      <FieldInput
                        name="firstName"
                        type="text"
                        label={t("crm:tableCrm.name")}
                        capitalize={true}
                      />
                    </Col>
                  </Row>
                  {/* Last name */}
                  <Row>
                    <Col md={12} lg={12}>
                      <FieldInput
                        name="lastName"
                        type="text"
                        label={t("crm:modalAddProspect.lastName")}
                        capitalize={true}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <DatePicker
                        label={t("modalCreateStudent:labels.dateBirth")}
                        placeholderText="DD/MM/YYYY"
                        dateFormat="dd/MM/yyyy"
                        name="birthDate"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        {...Locallanguage}
                        maxDate={new Date()}
                      />
                    </Col>
                  </Row>
                  {/* Roles */}
                  <Row>
                    <Col md={12} lg={12}>
                      <FormSelect
                        label={t("staff:modalStaff.selectRoles")}
                        name="permissions"
                        options={optionRoles}
                        placeholder={t(
                          "modalConfirmationsStaff.placeholderSelectRole"
                        )}
                        onChange={(event) => {
                          formik.setFieldValue("permissions", event.map((e) => e.value));
                        }}
                        value={formik.event}
                        isMulti
                        className="multiplePillSelect"
                      />
                    </Col>
                  </Row>
                  {/* Email */}
                  <Row>
                    <Col md={12} lg={12}>
                      <FieldInput
                        name="email"
                        type="text"
                        label={t("modalCreateRelative:labels.email")}
                      />
                    </Col>
                  </Row>
                  {/*  Password */}
                  <Row>
                    <Col md={11} sm={11} xs={11}>
                      <FormB.Group className="mb-3">
                        <FormB.Label>
                          {t("modalCreateRelative:labels.password")}
                          <OverlayTrigger
                            placement="top"
                            overlay={tooltipPassword}
                          >
                            <span
                              className="bi bi-question-circle-fill"
                              aria-hidden="true"
                              style={{
                                verticalAlign: "middle",
                                cursor: "pointer",
                                fontSize: "16px",
                                color: "#EA2C54",
                                marginLeft: "4px",
                                paddingTop: "0px",
                              }}
                            ></span>
                          </OverlayTrigger>
                        </FormB.Label>
                        <FormB.Control
                          name="password"
                          className={
                            formik.touched.password && formik.errors.password
                              ? "errorValidation"
                              : ""
                          }
                          type={typePassword}
                          placeholder="*************"
                          value={formik.values.password}
                          onChange={(e) =>
                            formik.setFieldValue("password", e.target.value)
                          }
                          onBlur={() =>
                            formik.setFieldTouched("password", true)
                          }
                        />
                        {formik.errors.password && formik.touched.password && (
                          <FormB.Text className="text-muted error">
                            {formik.errors.password}
                          </FormB.Text>
                        )}
                      </FormB.Group>
                    </Col>
                    <Col md={1} sm={1} xs={1} className="px-0">
                      <img
                        id="type_password"
                        src={
                          typePassword == "password"
                            ? "/img/eye-slash.svg"
                            : "/img/eye.svg"
                        }
                        onClick={showHide}
                        style={{
                          cursor: "pointer",
                          marginTop: "46px",
                        }}
                      />
                    </Col>
                  </Row>
                  {/* Test check */}
                  <Row>
                    <Col md={12} className="mt-3">
                      <FormCheck
                        name="test_account"
                        label={t("modalCreateRelative:labels.testAccount")}
                        type="checkbox"
                      />
                    </Col>
                  </Row>
                  {error && (
                    <div className="mtop20">
                      <Alert variant="warning">{error}</Alert>
                    </div>
                  )}
                  <Modal.Footer style={{ marginRight: "-11px" }}>
                    <Button
                      variant="light"
                      onClick={closeModal}
                      disabled={disabledCreateUSer}
                    >
                      {t("global:buttons.cancel")}
                    </Button>
                    <Button type="submit" disabled={disabledCreateUSer}>
                      {disabledCreateUSer ?

                        <TextLoading text={t("global:buttons.creating")} />
                        : t("global:titlePermissions.create")}
                    </Button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
      <ModalStaffCreated
        showModalStaffConfirmations={showModalStaffConfirmations}
        setShowModalStaffConfirmations={setShowModalStaffConfirmations}
        setShowModalStaff={props.setShowModalStaff}
        newUserCreated={newUserCreated}
      />
    </div>
  );
}

ModalCreateStaff.propTypes = {
  setShowModalStaff: PropTypes.func,
  showModalStaff: PropTypes.bool,
  listStaff: PropTypes.func,
};
